import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    RibbonErrorComponent,
    RibbonNoItemsComponent,
    RibbonProgressBarComponent
} from './components';

const COMPONENTS = [
    RibbonErrorComponent,
    RibbonNoItemsComponent,
    RibbonProgressBarComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    providers: []
})
export class RibbonAddonModule {

}

import {
    Component,
    Input,
    ViewEncapsulation,
    Optional,
    Attribute,
    OnInit,
    forwardRef
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormGroup,
    FormGroupDirective,
    NG_VALUE_ACCESSOR
} from '@angular/forms';
import {
    FADE_IN_CONTENT_BY_HEIGHT_OPACITY
} from '@appConstants';
import { IPasswordValidationMessage } from '../interfaces';
import { IItemValidationMessage } from '../interfaces';

@Component({
    selector: 'validation-messages',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './validationMessages.component.html',
    styleUrls: [
        './validationMessages.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ValidationMessagesComponent),
            multi: true
        }
    ],
    animations: [
        FADE_IN_CONTENT_BY_HEIGHT_OPACITY],
    host: {
        class: 'not-mark-invalid'
    }
})

export class ValidationMessagesComponent implements ControlValueAccessor, OnInit {

    private formGroup: FormGroup;

    @Input() public control: AbstractControl;
    @Input() public directHighlight: boolean;

    @Input() public required: string;
    @Input() public maxLength: string;
    @Input() public minLength: string;
    @Input() public pattern: string;
    @Input() public equalValues: string;
    @Input() public unique: string;
    @Input() public dateTimeInvalid: string;
    @Input() public max: string;
    @Input() public min: string;
    @Input() public email: string;
    @Input() public url: string;
    @Input() public integer: string;
    @Input() public float: string;
    @Input() public percent: string;
    @Input() public other: string;

    @Input() public password: IPasswordValidationMessage;

    @Input() public messages: Array<IItemValidationMessage>;

    constructor(
        @Optional() private parentFormGroup: FormGroupDirective,
        @Attribute('formControlName') private formControlName: string) {
    }

    public writeValue(obj: any): void {
    }

    public registerOnChange(fn: any): void {
    }

    public registerOnTouched(fn: any): void {
    }

    public setDisabledState?(isDisabled: boolean): void {

    }

    public ngOnInit(): void {

        if (this.parentFormGroup) {
            this.formGroup = this.parentFormGroup.form;
        }
        if (this.formGroup && this.formControlName) {
            this.control = this.formGroup.get(this.formControlName);
        }
    }
}

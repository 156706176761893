
import {
    Observable,
    of,
    Subject
} from 'rxjs';
import {
    AnalyticsStorageRemoteProvider,
    AnalyticsSearchCollectionResponseContract,
    AssetsSearchRequestContract
} from '@app/storage';
import {
    Injectable
} from '@angular/core';
import {
    map,
    mergeMap,
    takeUntil
} from 'rxjs/operators';
import { AssetsFilterTypeEnum } from '@app/core/common';
import { SearchCache } from '@app/core/analytics/interfaces';

@Injectable()
export class AssetsCollectionModel {

    private cancelSearchRequest: Subject<boolean> = new Subject<boolean>();
    private searchCache: SearchCache;

    constructor(
        private remoteProvider: AnalyticsStorageRemoteProvider,
    ) {
        this.searchCache = {};
    }

    public init(): Observable<void> {
        const selector = {} as AssetsSearchRequestContract;
        selector.count = 50;
        selector.offset = 0;
        selector.search = '';
        selector.searchBy = AssetsFilterTypeEnum.all;
        return this.remoteProvider
            .searchCollectionForRibbon(selector)
            .pipe(map((t) => {
                this.searchCache[selector.searchBy + selector.offset] = t;
            }));
    }

    public cancelRequest(): void {
        this.cancelSearchRequest.next(false);
    }

    public getSearchCollection = (selector: AssetsSearchRequestContract, cache: boolean): Observable<AnalyticsSearchCollectionResponseContract> => {
        this.cancelSearchRequest.next(true);
        return of([])
            .pipe(mergeMap(() => {
                const cacheKey = (selector.search || '') +
                    (selector.customerId || '') +
                    (selector.siteId || '') +
                    (selector.buildingId || '') +
                    selector.searchBy +
                    selector.offset;
                if (cache && this.searchCache[cacheKey]
                ) {
                    return of(this.searchCache[cacheKey]);
                } else {
                    return this.remoteProvider
                        .searchCollectionForRibbon(selector)
                        .pipe(map(t => t), takeUntil(this.cancelSearchRequest))
                        .pipe(
                            mergeMap(t => {
                                if (cache) {
                                    this.searchCache[cacheKey] = t;
                                }
                                return of(t);
                            }));
                }
            }));
    };

    public reset(): void {
        this.searchCache = {};
    }

}

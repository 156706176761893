import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    TimeLineComponent,
    TimeLineItemComponent,
    TimeLineRangeItemComponent
} from './components';
import { IconsModule } from '../icons/icons.module';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';

const COMPONENTS = [
    TimeLineComponent,
    TimeLineItemComponent,
    TimeLineRangeItemComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconsModule,
        TooltipModule
    ],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    providers: []
})
export class TimeLineChartModule {

}

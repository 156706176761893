<div class="ribbon-no-items">
  <div class="no-items-messageContainerWrapper">
    <div class="no-items-messageContainer">
      <div class="no-items-messageWrapper">
        <div class="no-items-message">
          {{message}}
        </div>
      </div>
    </div>
  </div>
</div>

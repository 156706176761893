<div class="ribbon-error">
  <div class="error-messageContainerWrapper">
    <div class="error-messageContainer">
      <div class="error-messageWrapper">
        <div class="error-message">
          {{message}}
        </div>
      </div>
    </div>
  </div>
</div>

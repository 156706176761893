import {
    AssetsTreeStorageRemoteProvider
} from '@app/storage';
import {
    Injectable
} from '@angular/core';
import { firstValueFrom } from 'rxjs';


@Injectable()
export class AssetsBreadcrumbsModel {

    private pFloorId: string;

    private pFloorName: string;
    public get floorName(): string {
        return this.pFloorName;
    }

    private pBuildingId: string;

    private pBuildingName: string;
    public get buildingName(): string {
        return this.pBuildingName;
    }

    private pSiteId: string;

    private pSiteName: string;
    public get siteName(): string {
        return this.pSiteName;
    }

    private pCustomerId: string;

    private pCustomerName: string;
    public get customerName(): string {
        return this.pCustomerName;
    }

    constructor(
        private assetsTreeStorageRemoteProvider: AssetsTreeStorageRemoteProvider,
    ) {

    }

    public async load(customerId: string, siteId: string, buildingId: string, floorId: string): Promise<void> {
        this.pFloorId = floorId;
        this.pBuildingId = buildingId;
        this.pSiteId = siteId;
        this.pCustomerId = customerId;
        const result = await firstValueFrom(this.assetsTreeStorageRemoteProvider
            .getAssetsBreadcrumbs(customerId, siteId, buildingId, floorId));
        this.pCustomerName = result.customerName;
        this.pSiteName = result.siteName;
        this.pBuildingName = result.buildingName;
        this.pFloorName = result.floorName;
    }

}

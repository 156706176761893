import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleChartsService } from './services';

@NgModule({
    imports: [
        CommonModule],
    exports: [],
    declarations: [],
    providers: [
        { provide: GoogleChartsService, useValue: new GoogleChartsService() }
    ]
})
export class GoogleChartsModule {

}

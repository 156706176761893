<div class="invalid-feedback-block"
     *ngIf="control?.invalid && (control?.dirty || control?.touched || directHighlight)"
     @fadeInContentByHeightOpacity>
    <div class="message-error"
         *ngIf="control.errors?.required"
         @fadeInContentByHeightOpacity>
        {{required}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.pattern"
         @fadeInContentByHeightOpacity>
        {{pattern}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.minlength"
         @fadeInContentByHeightOpacity>
        {{minLength}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.maxlength"
         @fadeInContentByHeightOpacity>
        {{maxLength}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.equalValues"
         @fadeInContentByHeightOpacity>
        {{equalValues}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.dateTimeInvalid"
         @fadeInContentByHeightOpacity>
        {{dateTimeInvalid}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.max"
         @fadeInContentByHeightOpacity>
        {{max}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.min"
         @fadeInContentByHeightOpacity>
        {{min}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.email"
         @fadeInContentByHeightOpacity>
        {{email}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.url"
         @fadeInContentByHeightOpacity>
        {{url}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.integer"
         @fadeInContentByHeightOpacity>
        {{integer}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.float"
         @fadeInContentByHeightOpacity>
        {{float}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.percent"
         @fadeInContentByHeightOpacity>
        {{percent}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.unique"
         @fadeInContentByHeightOpacity>
        {{unique}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.other"
         @fadeInContentByHeightOpacity>
        {{other}}
    </div>

    <div class="message-error"
         *ngIf="control.errors?.password?.hasNumber"
         @fadeInContentByHeightOpacity>
        {{password.hasNumber}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.password?.hasCapitalCase"
         @fadeInContentByHeightOpacity>
        {{password.hasCapitalCase}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.password?.hasSmallCase"
         @fadeInContentByHeightOpacity>
        {{password.hasSmallCase}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.password?.hasSpecialCharacters"
         @fadeInContentByHeightOpacity>
        {{password.hasSpecialCharacters}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.password?.hasMinLength"
         @fadeInContentByHeightOpacity>
        {{password.hasMinLength}}
    </div>
    <div class="message-error"
         *ngIf="control.errors?.password?.hasUnique"
         @fadeInContentByHeightOpacity>
        {{password.hasUnique}}
    </div>
    <ng-container *ngFor="let item of messages">
        <div class="message-error"
             *ngIf="control.errors[item.error]"
             @fadeInContentByHeightOpacity>
            {{item.message}}
        </div>
    </ng-container>
</div>
import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    PopoverComponent,
    ConfirmPopupComponent,
    PopoverDirective,
    ErrorPopupComponent,
    AccessDeniedPopupComponent
} from './components';
import { IconsModule } from '../icons/icons.module';
import {
    NkRippleModule
} from '@nkControls';

const COMPONENTS = [
    PopoverComponent,
    PopoverDirective,
    ConfirmPopupComponent,
    ErrorPopupComponent,
    AccessDeniedPopupComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconsModule,
        NkRippleModule
    ],
    exports: [COMPONENTS],
    declarations: [COMPONENTS]
})
export class PopupsModule {

}

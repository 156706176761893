import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IconsModule } from './icons/icons.module';
import { SearchModule } from './search/search.module';
import { PopupsModule } from './popups/popups.module';
import { RibbonAddonModule } from './ribbonAddon';
import { GoogleChartsModule } from './googleCharts';
import { TimeLineChartModule } from './timeLineChart/timeLineChart.module';
import { ValidationMessagesModule } from './validationMessages/validationMessages.module';
import { HighlightWordsModule } from './highlightWords/highlightWords.module';
import { AssetsBreadcrumbsModule } from './assetsBreadcrumbs/assetsBreadcrumbs.module';

const MODULES = [
    IconsModule,
    SearchModule,
    PopupsModule,
    RibbonAddonModule,
    GoogleChartsModule,
    TimeLineChartModule,
    ValidationMessagesModule,
    HighlightWordsModule,
    AssetsBreadcrumbsModule
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MODULES
    ],
    exports: [MODULES],
    providers: []
})
export class CommonControlsModule {

}

import {
    Component,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {
    AssetsFilterTypeEnum,
    ASSET_FILTER_TOKEN,
    CustomerTypeEnum,
    IAssetFilter
} from '@app/core';
import { Observable } from 'rxjs';
import {
    NkSelect
} from '@nkControls';
import {
    AssetsSearchCollectionResponseContract,
    AssetsSearchItemResponseContract,
    AssetsSearchRequestContract
} from '@app/storage';
import {
    map,
    skip
} from 'rxjs/operators';
import { SubscriptionNotifier } from '@app/shared';
import {
    FLOOR_SEARCH_COLLECTION_TOKEN,
    BUILDING_SEARCH_COLLECTION_TOKEN,
    SITE_SEARCH_COLLECTION_TOKEN,
    CUSTOMER_SEARCH_COLLECTION_TOKEN
} from '../tokens';
import { AssetsCollectionModel } from '../models';
import { IAssetBreadcrumbItem } from '../interfaces';


@Component({
    selector: 'assets-breadcrumbs',
    templateUrl: 'assetsBreadcrumbs.component.html',
    styleUrls: ['./assetsBreadcrumbs.component.scss'],
    providers: [
    ],
    host: {
        class: 'assets-breadcrumbs'
    }
})

export class AssetsBreadcrumbsComponent implements OnInit, OnDestroy {

    private subscriptions: SubscriptionNotifier = new SubscriptionNotifier();

    @ViewChild('assetChildSelect', { static: true }) assetChildSelect: NkSelect;

    public assetsFilterType = AssetsFilterTypeEnum;

    constructor(
        @Inject(FLOOR_SEARCH_COLLECTION_TOKEN) private readonly floorSearchCollectionModel: AssetsCollectionModel,
        @Inject(BUILDING_SEARCH_COLLECTION_TOKEN) private readonly buildingSearchCollectionModel: AssetsCollectionModel,
        @Inject(SITE_SEARCH_COLLECTION_TOKEN) private readonly siteSearchCollectionModel: AssetsCollectionModel,
        @Inject(CUSTOMER_SEARCH_COLLECTION_TOKEN) private readonly customerSearchCollectionModel: AssetsCollectionModel,
        private childAssetCollectionModel: AssetsCollectionModel,
        @Inject(ASSET_FILTER_TOKEN) public readonly filter: IAssetFilter
    ) {
    }

    public ngOnInit(): void {
        this.filter
            .onFilterChanged
            .pipe(skip(1))
            .pipe(this.subscriptions.register())
            .subscribe(() => {
                this.assetChildSelect.clear();
                this.childAssetCollectionModel.reset();
                this.floorSearchCollectionModel.reset();
                this.buildingSearchCollectionModel.reset();
                this.siteSearchCollectionModel.reset();
                this.customerSearchCollectionModel.reset();
            });
    }

    public ngOnDestroy(): void {
        this.subscriptions.destroy();
    }

    public selectCustomer(): void {
        this.filter.setFilterBySearchResult(
            {
                customerId: this.filter.customerId,
                customerName: this.filter.customerName,
                customerType: null,
                siteId: null,
                siteName: null,
                buildingId: null,
                buildingName: null,
                floorId: null,
                floorName: null,
                isFavorite: false,
                timezone: this.filter.timezone
            }
        );
    }

    public selectSite(): void {
        this.filter.setFilterBySearchResult(
            {
                customerId: this.filter.customerId,
                customerName: this.filter.customerName,
                customerType: null,
                siteId: this.filter.siteId,
                siteName: this.filter.siteName,
                buildingId: null,
                buildingName: null,
                floorId: null,
                floorName: null,
                isFavorite: false,
                timezone: this.filter.timezone
            }
        );
    }

    public selectBuilding(): void {
        this.filter.setFilterBySearchResult(
            {
                customerId: this.filter.customerId,
                customerName: this.filter.customerName,
                customerType: null,
                siteId: this.filter.siteId,
                siteName: this.filter.siteName,
                buildingId: this.filter.buildingId,
                buildingName: this.filter.buildingName,
                floorId: null,
                floorName: null,
                isFavorite: false,
                timezone: this.filter.timezone
            }
        );
    }

    public getFloorCollection = (): Observable<Array<IAssetBreadcrumbItem>> => {
        const selector = {
            customerId: this.filter.customerId,
            siteId: this.filter.siteId,
            buildingId: this.filter.buildingId,
            searchBy: AssetsFilterTypeEnum.floors,
            offset: 0,
            count: 100000
        } as AssetsSearchRequestContract;

        return this.floorSearchCollectionModel
            .getSearchCollection(selector, false)
            .pipe(map((t: AssetsSearchCollectionResponseContract) => {
                const list: Array<IAssetBreadcrumbItem> = [];
                t.items.forEach(c => {
                    list.push({ id: c.floorId, name: c.floorName, timezone: c.timezone });
                })
                return list;
            }));
    }

    public getBuildingCollection = (): Observable<Array<IAssetBreadcrumbItem>> => {
        const selector = {
            customerId: this.filter.customerId,
            siteId: this.filter.siteId,
            buildingId: null,
            searchBy: AssetsFilterTypeEnum.buildings,
            offset: 0,
            count: 100000
        } as AssetsSearchRequestContract
        return this.buildingSearchCollectionModel
            .getSearchCollection(selector, false)
            .pipe(map((t: AssetsSearchCollectionResponseContract) => {
                const list: Array<IAssetBreadcrumbItem> = [];
                t.items.forEach(c => {
                    list.push({ id: c.buildingId, name: c.buildingName, timezone: c.timezone });
                })
                return list;
            }));
    }

    public getSiteCollection = (): Observable<Array<IAssetBreadcrumbItem>> => {
        const selector = {
            customerId: this.filter.customerId,
            siteId: null,
            buildingId: null,
            searchBy: AssetsFilterTypeEnum.sites,
            offset: 0,
            count: 100000
        } as AssetsSearchRequestContract
        return this.siteSearchCollectionModel
            .getSearchCollection(selector, false)
            .pipe(map((t: AssetsSearchCollectionResponseContract) => {
                const list: Array<IAssetBreadcrumbItem> = [];
                t.items.forEach(c => {
                    list.push({ id: c.siteId, name: c.siteName, timezone: c.timezone });
                })
                return list;
            }));
    }

    public getCustomerCollection = (): Observable<Array<IAssetBreadcrumbItem>> => {
        const selector = {
            customerId: null,
            siteId: null,
            buildingId: null,
            searchBy: AssetsFilterTypeEnum.customers,
            offset: 0,
            count: 100000
        } as AssetsSearchRequestContract
        return this.customerSearchCollectionModel
            .getSearchCollection(selector, false)
            .pipe(map((t: AssetsSearchCollectionResponseContract) => {
                const list: Array<IAssetBreadcrumbItem> = [];
                t.items
                    .filter(c => c.customerType === CustomerTypeEnum.END_CUSTOMER)
                    .forEach(c => {
                        list.push({ id: c.customerId, name: c.customerName, timezone: c.timezone });
                    })
                return list;
            }));
    }

    public getChildAssetCollection = (): Observable<Array<IAssetBreadcrumbItem>> => {
        const searchBy = this.filter.getChildSearchType();
        const selector = {
            customerId: this.filter.customerId,
            siteId: this.filter.siteId,
            buildingId: this.filter.buildingId,
            offset: 0,
            searchBy: searchBy,
            count: 100000
        } as AssetsSearchRequestContract;
        return this.childAssetCollectionModel
            .getSearchCollection(selector, false)
            .pipe(map((t: AssetsSearchCollectionResponseContract) => {
                const list: Array<IAssetBreadcrumbItem> = [];
                t.items
                    .forEach(c => {
                        switch (searchBy) {
                            case AssetsFilterTypeEnum.floors:
                                list.push({ id: c.floorId, name: c.floorName, timezone: c.timezone });
                                break;
                            case AssetsFilterTypeEnum.buildings:
                                list.push({ id: c.buildingId, name: c.buildingName, timezone: c.timezone });
                                break;
                            case AssetsFilterTypeEnum.sites:
                                list.push({ id: c.siteId, name: c.siteName, timezone: c.timezone });
                                break;
                            case AssetsFilterTypeEnum.customers:
                                if (c.customerType === CustomerTypeEnum.END_CUSTOMER) {
                                    list.push({ id: c.customerId, name: c.customerName, timezone: c.timezone });
                                }
                                break;
                            default:
                                break;
                        }
                    })
                return list;
            }));
    }

    public onSelectAsset(item: any, type: AssetsFilterTypeEnum): void {

        const data = {
        } as AssetsSearchItemResponseContract;

        switch (type) {
            case AssetsFilterTypeEnum.floors:
                data.floorId = item.selected.id;
                data.floorName = item.selected.name;
                data.buildingId = this.filter.buildingId;
                data.buildingName = this.filter.buildingName;
                data.siteId = this.filter.siteId;
                data.siteName = this.filter.siteName;
                data.customerId = this.filter.customerId;
                data.customerName = this.filter.customerName;
                data.timezone = item.selected.timezone;
                break;
            case AssetsFilterTypeEnum.buildings:
                data.buildingId = item.selected.id;
                data.buildingName = item.selected.name;
                data.siteId = this.filter.siteId;
                data.siteName = this.filter.siteName;
                data.customerId = this.filter.customerId;
                data.customerName = this.filter.customerName;
                data.timezone = item.selected.timezone;
                break;
            case AssetsFilterTypeEnum.sites:
                data.customerId = this.filter.customerId;
                data.customerName = this.filter.customerName;
                data.siteId = item.selected.id;
                data.siteName = item.selected.name;
                data.timezone = item.selected.timezone;
                break;
            case AssetsFilterTypeEnum.customers:
                data.customerId = item.selected.id;
                data.customerName = item.selected.name;
                data.timezone = item.selected.timezone;
                break;
            default:
                break;
        };
        this.filter.setFilterBySearchResult(data);
    }

    public onSelectChildAsset(item: any): void {

        let searchBy = this.filter.getChildSearchType();
        const data: AssetsSearchItemResponseContract = {
            customerId: this.filter.customerId,
            customerName: this.filter.customerName,
            siteId: this.filter.siteId,
            siteName: this.filter.siteName,
            customerType: null,
            buildingId: this.filter.buildingId,
            buildingName: this.filter.buildingName,
            floorId: this.filter.floorId,
            floorName: this.filter.floorName,
            isFavorite: false,
            timezone: item.selected.timezone
        };
        switch (searchBy) {
            case AssetsFilterTypeEnum.floors:
                data.floorId = item.selected.id;
                data.floorName = item.selected.name;
                break;
            case AssetsFilterTypeEnum.buildings:
                data.buildingId = item.selected.id;
                data.buildingName = item.selected.name;
                break;
            case AssetsFilterTypeEnum.sites:
                data.siteId = item.selected.id;
                data.siteName = item.selected.name;
                break;
            default:
                break;
        };
        this.filter.setFilterBySearchResult(data);
    }

    public getAssetIconName(): string {
        let searchBy = this.filter.getChildSearchType();
        switch (searchBy) {
            case AssetsFilterTypeEnum.floors:
                return 'floor';
            case AssetsFilterTypeEnum.buildings:
                return 'building';
            case AssetsFilterTypeEnum.sites:
                return 'site';
        };
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    TranslateLoader,
    TranslateModule
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
    HttpClient
} from '@angular/common/http';
import 'src/app/shared/extensions/dateTime.extension';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import { CommonControlsModule } from './controls/commonControls.module';
import * as moment from 'moment';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatDialogModule
} from '@angular/material/dialog';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TextFieldModule } from '@angular/cdk/text-field';
import { TooltipModule } from 'ng2-tooltip-directive-major-angular-updates';
import {
    NkRibbonModule,
    NkRippleModule,
    NkSelectModule,
    NkTableModule,
    NkPseudoCheckboxModule
} from '@nkControls';
import { PerfectScrollbarModule } from 'ngx-om-perfect-scrollbar';
import { DoubleDashFormat } from './pipes';
import { FileUploadModule } from 'ng2-file-upload';
import { environment } from '@environments/environment';
import {
    NgxMaskDirective,
    NgxMaskPipe,
    provideNgxMask
} from 'ngx-mask';

export const HttpLoaderFactory = (httpClient: HttpClient): any => new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json?v=' + environment.version);

const COMMON_MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDaterangepickerMd.forRoot({
        format: 'DD.MM.YYYY',
        daysOfWeek: moment.weekdaysShort(),
        monthNames: moment.months(),
        firstDay: 1,
    }),
    CommonControlsModule,
    MatDialogModule,
    NkRibbonModule,
    NkSelectModule,
    NkRippleModule,
    NkPseudoCheckboxModule,
    NkTableModule,
    PerfectScrollbarModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatRadioModule,
    FileUploadModule,
    TextFieldModule,
    TooltipModule,
    NgxMaskDirective,
    NgxMaskPipe
];

@NgModule({
    declarations: [
        DoubleDashFormat,
    ],
    imports: [
        COMMON_MODULES,
        TranslateModule.forChild({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        COMMON_MODULES,
        TranslateModule,
        DoubleDashFormat
    ],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: { hasBackdrop: true }
        },
        provideNgxMask()
    ]
})
export class SharedModule { }


import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    SearchComponent
} from './components';
import { IconsModule } from '../icons/icons.module';

const COMPONENTS = [
    SearchComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IconsModule
    ],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    providers: []
})
export class SearchModule {

}

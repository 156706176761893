import {
    Component,
    Input
} from '@angular/core';
import {
    defaultFindChunks,
    defaultSanitize
} from '../helpers';
import {
    Chunk,
    findAll
} from '../helpers/highLightWords.helper';

@Component({
    selector: 'highlight-words',
    templateUrl: './highlightWords.component.html',
})
export class HighlightWordsComponent {

    @Input() textToHighlight = '';
    @Input() searchWords: string[] = [];
    @Input() highlightClassName = 'highlight';
    @Input() autoEscape = true;
    @Input() caseSensitive = false;
    @Input() findChunks = defaultFindChunks;
    @Input() sanitize = defaultSanitize;

    get chunks(): Chunk[] {
        return findAll({
            textToHighlight: this.textToHighlight,
            searchWords: this.searchWords,
            autoEscape: this.autoEscape,
            caseSensitive: this.caseSensitive,
            findChunks: this.findChunks,
            sanitize: this.sanitize,
        });
    }
}
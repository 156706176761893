import { Injectable } from '@angular/core';

@Injectable()
export class GoogleChartsService {

    constructor(
    ) {
        const tag = document.createElement('script');
        tag.async = false;
        tag.src = 'https://www.gstatic.com/charts/loader.js';
        document.body.appendChild(tag);
    }
}

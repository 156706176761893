import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
    ValidationMessagesComponent
} from './components';

const COMPONENTS = [
    ValidationMessagesComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    providers: []
})
export class ValidationMessagesModule {

}

<div class="content">
      <div class="content-row"
            [formGroup]="filter.form">
            <div class="breadcrumbs">
                  <span *ngIf="!filter.customerId"
                        class="item"></span>
                  <span *ngIf="filter.customerId"
                        (click)="selectCustomer()"
                        class="item">{{filter.customerName}}</span>
                  <nk-select *ngIf="filter.customerId"
                        class="asset-select nk-select-default"
                        required
                        formControlName="customerId"
                        [nkSelectDataSource]="getCustomerCollection"
                        [nkSelectPerfectScroll]="true"
                        [nkSelectCache]="false"
                        (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.customers)"
                        [nkSelectPrimaryKey]="'id'"
                        [appendTo]="'self'">
                        <ng-template #titleTemplate
                              let-controlItems="controlItems">
                              <div class="text-overflow">
                              </div>
                        </ng-template>
                        <ng-template #itemTemplate
                              let-controlItem="controlItem">
                              <div class="icon customer"></div>
                              <div class="field-item text-overflow"
                                    title="{{controlItem.name}}">
                                    {{controlItem.name}}
                              </div>
                        </ng-template>
                  </nk-select>
                  <span *ngIf="filter.siteId"
                        class="arrow-right"></span>
                  <span *ngIf="filter.siteId"
                        (click)="selectSite()"
                        class="item">{{filter.siteName}}</span>
                  <nk-select *ngIf="filter.siteId"
                        class="asset-select nk-select-default"
                        required
                        formControlName="siteId"
                        [nkSelectDataSource]="getSiteCollection"
                        [nkSelectPerfectScroll]="true"
                        [nkSelectCache]="false"
                        (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.sites)"
                        [nkSelectPrimaryKey]="'id'"
                        [appendTo]="'self'">
                        <ng-template #titleTemplate
                              let-controlItems="controlItems">
                              <div class="text-overflow">
                              </div>
                        </ng-template>
                        <ng-template #itemTemplate
                              let-controlItem="controlItem">
                              <div class="icon site"></div>
                              <div class="field-item text-overflow"
                                    title="{{controlItem.name}}">
                                    {{controlItem.name}}
                              </div>
                        </ng-template>
                  </nk-select>
                  <span *ngIf="filter.buildingId"
                        class="arrow-right"></span>
                  <span *ngIf="filter.buildingId"
                        (click)="selectBuilding()"
                        class="item">{{filter.buildingName}}</span>
                  <nk-select *ngIf="filter.buildingId"
                        class="asset-select nk-select-default"
                        required
                        formControlName="buildingId"
                        [nkSelectDataSource]="getBuildingCollection"
                        [nkSelectPerfectScroll]="true"
                        [nkSelectCache]="false"
                        (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.buildings)"
                        [nkSelectPrimaryKey]="'id'"
                        [appendTo]="'self'">
                        <ng-template #titleTemplate
                              let-controlItems="controlItems">
                              <div class="text-overflow">
                              </div>
                        </ng-template>
                        <ng-template #itemTemplate
                              let-controlItem="controlItem">
                              <div class="icon building"></div>
                              <div class="field-item text-overflow"
                                    title="{{controlItem.name}}">
                                    {{controlItem.name}}
                              </div>
                        </ng-template>
                  </nk-select>
                  <span *ngIf="filter.floorId"
                        class="arrow-right"></span>
                  <span *ngIf="filter.floorId"
                        class="item">{{filter.floorName}}</span>
                  <nk-select *ngIf="filter.floorId"
                        class="asset-select nk-select-default"
                        required
                        formControlName="floorId"
                        [nkSelectDataSource]="getFloorCollection"
                        [nkSelectCache]="false"
                        [nkSelectPerfectScroll]="true"
                        (nkSelectOnSelectItem)="onSelectAsset($event, assetsFilterType.floors)"
                        [nkSelectPrimaryKey]="'id'"
                        [appendTo]="'self'">
                        <ng-template #titleTemplate
                              let-controlItems="controlItems">
                              <div class="text-overflow">
                              </div>
                        </ng-template>
                        <ng-template #itemTemplate
                              let-controlItem="controlItem">
                              <div class="icon floor"></div>
                              <div class="field-item text-overflow"
                                    title="{{controlItem.name}}">
                                    {{controlItem.name}}
                              </div>
                        </ng-template>
                  </nk-select>
            </div>
            <nk-select #assetChildSelect
                  class="asset-child-select nk-select-default"
                  [class.hidden]="filter.floorId || !filter.customerId"
                  required
                  [nkSelectDataSource]="getChildAssetCollection"
                  [nkSelectPerfectScroll]="true"
                  [nkSelectCache]="false"
                  (nkSelectOnSelectItem)="onSelectChildAsset($event)"
                  [nkSelectPrimaryKey]="'id'"
                  [appendTo]="'self'">
                  <ng-template #titleTemplate
                        let-controlItems="controlItems">
                        <div class="text-overflow">
                        </div>
                  </ng-template>
                  <ng-template #itemTemplate
                        let-controlItem="controlItem">
                        <div class="icon"
                              [ngClass]="getAssetIconName()"></div>
                        <div class="field-item text-overflow"
                              title="{{controlItem.name}}">
                              {{controlItem.name}}
                        </div>
                  </ng-template>
            </nk-select>
      </div>
</div>
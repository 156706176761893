import { NgModule, } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    FormsModule,
    ReactiveFormsModule
} from '@angular/forms';
import {
    AssetsBreadcrumbsComponent
} from './components';
import {
    NkSelectModule
} from '@nkControls';
import { AnalyticsStorageRemoteProvider } from '@app/storage';
import {
    BUILDING_SEARCH_COLLECTION_TOKEN,
    CUSTOMER_SEARCH_COLLECTION_TOKEN,
    FLOOR_SEARCH_COLLECTION_TOKEN,
    SITE_SEARCH_COLLECTION_TOKEN
} from './tokens';
import { AssetsCollectionModel } from './models';

const COMPONENTS = [
    AssetsBreadcrumbsComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NkSelectModule
    ],
    exports: [COMPONENTS],
    declarations: [COMPONENTS],
    providers: [
        AnalyticsStorageRemoteProvider,
        AssetsCollectionModel,
        { provide: FLOOR_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
        { provide: BUILDING_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
        { provide: SITE_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
        { provide: CUSTOMER_SEARCH_COLLECTION_TOKEN, useClass: AssetsCollectionModel },
    ]
})
export class AssetsBreadcrumbsModule {

}
